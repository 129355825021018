 
  .FuturaSpinner {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  .PageLoaderLogo > img {
    position: absolute;
    top: 16px;
    left: 16px;
    height: 124px;
    width: 124px;
  }
  
  .FuturaSpinner > img {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .SpinnerXL{
    height: 156px;
    width: 156px;
  }
  
  .SpinnerL {
    height: 96px;
    width: 96px;
  }
  
  .SpinnerMed {
    height: 48px;
    width: 48px;
  }
  
  .SpinnerSm {
    height: 24px;
    width: 24px;
  }
  
  .Large .FuturaSpinner.SpinnerButton > img {
    width: 20px;
    height: 20px;
  }
  
  .Medium .FuturaSpinner.SpinnerButton > img {
    width: 16px;
    height: 16px;
  }
  
  .Small .FuturaSpinner.SpinnerButton > img {
    width: 14px;
    height: 14px;
  }
  